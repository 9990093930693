@media screen and (max-width: 400px) {
  #about,
  #services,
  #testimonials,
  #cursos,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
  #cursos h2,
  #portfolio h2,
  #testimonials h2,
  #contact h2{
    font-size: 4em;
  }
}
